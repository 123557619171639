export const getVersion = (): string => {
    const version = process.env.REACT_APP_VERSION;
    if (version == null) {
        console.warn(
            'Version is not added to environment. Add REACT_APP_VERSION to .env'
        );
        return '0.0.1';
    }
    return version;
};

export const getAppName = (): string => {
    const name = process.env.REACT_APP_NAME;
    if (name == null) {
        console.warn(
            'App name is not added to environment. Add REACT_APP_NAME to .env'
        );
        return 'app';
    }
    return name;
};

export const isProduction = (): boolean =>
    process.env.NODE_ENV === 'production';

export const getApplicationRoot = (): HTMLElement => {
    return (
        document.getElementById(getAppName() + '-root') ??
        document.getElementById('root') ??
        document.body
    );
};

export const getPortalRoot = (): HTMLElement => {
    const portalRootId = `${getAppName()}-portal-root`;
    let portalRoot = document.getElementById(portalRootId);
    if (portalRoot === null) {
        const root = getApplicationRoot().parentElement;
        portalRoot = document.createElement('div');
        portalRoot.id = portalRootId;

        root?.appendChild(portalRoot);
    }
    return portalRoot;
};

export const isStandaloneApplication = (): boolean =>
    window.matchMedia('(display-mode: standalone)').matches ||
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window.navigator as any).standalone;
