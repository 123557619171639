import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './assets/css/index.css';
import { getApplicationRoot } from './env';

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    getApplicationRoot()
);

reportWebVitals(console.log);
