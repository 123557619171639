import React, { Suspense } from 'react';
const Provider = React.lazy(() => import('./Provider'));
const Layout = React.lazy(() => import('./layout/components/Layout'));

function App(): React.ReactElement {
    return (
        <Suspense fallback={null}>
            <Provider>
                <Layout />
            </Provider>
        </Suspense>
    );
}

export default App;
